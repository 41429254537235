body {
  color: #333333;
}

*:focus {
  outline: none !important;
}
::placeholder {
  color: #c5c5d0 !important;
}

/* Input range */
input[type='range'] {
  -webkit-appearance: none;
  @apply bg-gray-200 border border-gray-500 rounded-full w-full h-4;
}
input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  @apply bg-altGreen w-6 h-6 rounded-full;
}

input[type='range']::-moz-range-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  @apply bg-altGreen w-6 h-6 rounded-full;
}
input[type='range']::-ms-track {
  @apply w-full h-4;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type='range']::-ms-fill-upper {
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  cursor: pointer;
  @apply bg-altGreen w-6 h-6 rounded-full;
}
input[type='range']:focus::-ms-fill-lower {
  @apply bg-trueGray-200;
}
input[type='range']:focus::-ms-fill-upper {
  @apply bg-gray-200;
}
